<template>
    <div class="my-0 py-0">
        <v-card :loading="loading" height="100vh">
            <v-card-title>
                <v-btn
                    small
                    icon
                    @click="close"
                    color="primary"
                    class="ml-n3 mr-2"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <h2 class="font-weight-regular">
                    {{ process }}
                    <span class="font-weight-black"
                        >{{ bom.code }} | {{ bom.project.reference }}
                        {{ bom.project.name }}</span
                    >
                </h2>
                <v-spacer />
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text>
                <v-container fluid ref="container" class="px-0 mt-1 pb-0 mb-0">
                    <v-row no-gutters ref="details">
                        <v-col cols="4" class="pl-0 pt-2">
                            <div>
                                <p class="text-capitalize">
                                    <strong>STATUS: </strong>
                                    {{ ` ${bom.status}` }}
                                </p>
                                <p class="text-capitalize">
                                    <strong>CREATED BY: </strong>
                                    {{
                                        ` ${
                                            bom.creator && bom.creator.name
                                                ? bom.creator.name
                                                : bom.createdBy
                                        }`
                                    }}
                                </p>
                                <p class="text-capitalize">
                                    <strong>PROJECT MANAGER: </strong>
                                    {{
                                        bom.projectManager &&
                                        bom.projectManager.name
                                            ? bom.projectManager.name
                                            : bom.projectManager
                                    }}
                                </p>
                            </div>
                        </v-col>
                        <v-col cols="4" class="pl-0 pt-2">
                            <div>
                                <p>
                                    <strong>CREATED: </strong
                                    >{{
                                        ` ${formatDate(
                                            bom.createdOn.seconds ||
                                                bom.createdOn._seconds
                                        )}`
                                    }}
                                </p>
                                <v-textarea
                                    v-model="bom.notes"
                                    prepend-icon="mdi-text"
                                    prefix="Notes: "
                                    hide-details
                                    rows="1"
                                    class="pt-0"
                                    :disabled="true"
                                >
                                </v-textarea>
                            </div>
                        </v-col>
                        <v-spacer />
                        <v-col cols="4" class="d-flex justify-end mb-3">
                            <canvas id="BOMqr"></canvas>
                        </v-col>
                    </v-row>
                </v-container>
                <div class="mt-4">
                    <v-data-table
                        id="virtual-scroll-table"
                        :headers="headers"
                        :items="bom.items || []"
                        class="elevation-0"
                        :mobile-breakpoint="0"
                        :loading="loading"
                        disable-pagination
                        hide-default-footer
                        :style="`height: ${itemsHeight}px`"
                    >
                        <template v-slot:top>
                            <v-row
                                no-gutter
                                class="mt-0 mx-0 pt-4 pb-0 pb-4"
                                style="background-color: #eeeeee"
                            >
                                <v-col cols="12" class="d-flex">
                                    <h2 class="mx-4 my-n3">ITEMS</h2>
                                    <v-spacer />
                                </v-col>
                            </v-row>
                        </template>
                        <!--ITEMS-->
                        <template v-slot:[`item.index`]="{ index }">
                            <p class="my-0">
                                {{ index + 1 }}
                            </p>
                        </template>
                        <template v-slot:[`item.code`]="{ item }">
                            <div class="d-flex justify-center">
                                <p class="my-0">{{ item.code }}</p>
                            </div>
                        </template>
                        <template v-slot:[`item.description`]="{ item }">
                            <div class="d-flex">
                                <p class="my-0">{{ item.description }}</p>
                            </div>
                        </template>
                        <template v-slot:[`item.total`]="{ item }">
                            <div class="d-flex justify-center">
                                <p class="my-0">
                                    {{ item.quantity }}
                                </p>
                            </div>
                        </template>
                        <template v-slot:[`item.partial`]="{ item }">
                            <div class="d-flex justify-center">
                                <p class="my-0">
                                    {{ item.requests }}
                                </p>
                            </div>
                        </template>
                        <template v-slot:[`item.store`]="{ item }">
                            <div class="d-flex justify-center">
                                <p class="my-0">
                                    {{ item.store }}
                                </p>
                            </div>
                        </template>
                        <template v-slot:[`item.requestForClosing`]="{ item }">
                            <div class="d-flex justify-center">
                                <p class="my-0">
                                    {{ getClosingPartialUser(item) }}
                                </p>
                            </div>
                        </template>
                        <template v-slot:[`item.notes`]="{ item }">
                            <div class="d-flex justify-center">
                                <p class="my-0">
                                    {{ getClosingPartialNotes(item) }}
                                </p>
                            </div>
                        </template>
                    </v-data-table>
                </div>
            </v-card-text>
            <v-card-actions class="mx-3 mt-0 pt-0">
                <v-spacer />
                <v-btn
                    v-if="closePartialPermission"
                    rounded
                    color="#FF9800"
                    class="white--text mr-2"
                    @click="closeWarning = true"
                >
                    CLOSE
                </v-btn>
            </v-card-actions>
        </v-card>
        <!--Error Alert-->
        <Errors />
        <!--Close BOM-->
        <v-dialog
            v-model="closeWarning"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="closeWarning">
                <v-card-title class="text-h5"
                    >Close partial delivery</v-card-title
                >
                <v-card-text>
                    Are you sure you want to close this partial delivery?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="error" @click="closeWarning = false">
                        CANCEL
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="closeBOMPartials"
                        color="primary"
                        :loading="loading"
                    >
                        CLOSE
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'
import qrious from 'qrious'
import API from '@/services/api'

export default {
    name: 'ClosingPartialDelivery',
    props: {
        bom: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        process: {
            type: String,
            required: true,
            default: '',
        },
    },
    components: {
        Errors: () => import('@/components/Layout/Errors'),
    },
    data: () => ({
        closeWarning: false,
        loading: false,
        height: 0,
        itemsHeight: 0,
        prevNotes: null,
        closePartialPermission: false,
        headers: [
            {
                text: 'INDEX',
                value: 'index',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CODE',
                value: 'code',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'TOTAL',
                value: 'total',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PARTIAL',
                value: 'partial',
                align: 'center',
                sortable: false,
            },
            {
                text: 'REMAINING',
                value: 'remaining',
                align: 'center',
                sortable: false,
            },
            {
                text: 'STORE',
                value: 'store',
                align: 'center',
                sortable: false,
            },
            {
                text: 'REQUEST FOR CLOSING',
                value: 'requestForClosing',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'center',
                sortable: false,
            },
        ],
    }),
    async mounted() {
        try {
            this.prevNotes = this.bom.notes
            // qr code
            new qrious({
                element: document.getElementById('BOMqr'),
                value: `https://es-metals-project-agenda.web.app/work-orders/main-work-orders?bomId=${this.bom.id}`,
                size: 150,
            })
            // retrieve user
            const {
                data: { user },
            } = await API.getMyInfo()
            if (user.permissions.includes('closePartial')) {
                this.closePartialPermission = true
            }
            this.onResize()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        close() {
            this.$emit('close')
        },
        getClosingPartialNotes(item) {
            if (item.reasonsForClosing) {
                return item.reasonsForClosing.notes
            }
            return ''
        },
        getClosingPartialUser(item) {
            if (item.reasonsForClosing) {
                return item.reasonsForClosing.userName
            }
            return ''
        },
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                details: { clientHeight: detailsHeight },
            } = this.$refs
            this.height =
                window.innerHeight - containerHeight - detailsHeight + 50
            this.itemsHeight = window.innerHeight - 345
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')} ${moment
                .unix(seconds)
                .format('LT')}`
        },
        async closeBOMPartials() {
            try {
                this.loading = true
                await API.closeBOMPartials({
                    id: this.bom.id,
                    itemCode: this.bom.items[0].code,
                    requestId: this.bom.items[0].partialDeliveryId,
                    workOrderId: this.bom.workOrderId,
                })
                this.closeWarning = false
                this.$emit('remove', this.bom.items[0].partialDeliveryId)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style></style>
